const universities = {
  Bizerte: [
    "FSB",
    "ISETB",
    "ISSAT Mateur",
    "Enib",
    "ISGB",
    "IPEIB",
    "ISPAB",
    "ESA Mateur",
  ],
  Nabeul: [
    "FSEGN",
    "ISETN",
    "ISBAN",
    "ISLN",
    "IPEIN",
    "ISETK",
    "UMLT",
    "Leaders University",
    "ITBS",
    "IMSET",
    "ISTIC",
    "ISSTE",
    "ENSTAB",
  ],
  Monastir: [
    "FMDM",
    "FMM",
    "FPHM",
    "ESSTSM",
    "ISBM",
    "ISIMM",
    "ENIM",
    "IPEIM",
    "FSM",
    "ISLAAT",
    "ISEAH",
    "ISET Mahdia",
    "ISIMMa",
    "FSEG",
    "ISET Ksar Hlel",
    "ESPRIT",
    "Polytechnique",
    "ISSATM",
    "ISMM",
    "ISAM",
  ],
  Gabes: [
    "ISLG",
    "ISET",
    "ISG",
    "ENIG",
    "ISSAT",
    "ESSAT",
    "ISIMG",
    "FSG",
    "ISSIG",
    "ISAMG",
    "IMSET gabes",
  ],
  Sfax: [
    "FLSH",
    "ENIS",
    "FSEG",
    "ISB",
    "IIT",
    "IHE",
    "IPSAS",
    "ISMS",
    "ISIMS",
    "IHEC",
    "FMSF",
    "ESC",
    "FSS",
    "ISAAS",
    "ISSEPS",
    "ISBS",
    "ESSTSS",
    "ISSIS",
    "ISGI",
    "Enetcom",
    "ISAMS",
    "FDS",
    "Musique route tunis",
    "ESPIN",
    "IPEIS",
    "ISET",
  ],
  Sousse: [
    "FMS",
    "Faculté de Santé",
    "Infirmerie ISSIS",
    "ISIS",
    "Agronomique",
    "Institut de Musique",
    "Faculté de Droit",
    "EPI",
    "ESTIM",
    "IHE",
  ],
  "Campus Carthage": [
    "IHEC Carthage",
    "IHET",
    "ENAU",
    "ISLT",
    "INSAT",
    "SUPCOM",
    "SMU ( MSB +  Medtech )",
    "UTC",
    "UIK",
    "EBS",
    "Polytech INTL",
    "ESAD",
    "Polytech centrale",
    "Time université",
    "UPES",
    "FSJPST",
    "INAT",
    "ESTI",
    "IPEST",
    "ISTEUB",
    "ISSTE",
    "ISEPBG",
    "ESIAT",
    "ISCE",
    "INTEST",
    "INRGREF",
    "INRAT",
    "ESSAD",
    "ENIcarthage",
    "MiT Tunis",
  ],
  "Campus Mannouba": [
    "ESC",
    "ISCAE",
    "ESEN",
    "ENSI",
    "ISAMM",
    "FLAH",
    "IPSI",
    "ISD",
    "Sup sat",
    "UMM",
    "ENMV",
    "ESSTED",
    "ISBST",
    "ISSEP",
    "UPSAT",
    "Holberton school",
  ],
  "Campus Manar": [
    "FSEGT",
    "FST",
    "FDSPT",
    "ISI",
    "ISET Rades",
    "ISET'Com",
    "IBLV",
    "ISSBAT",
    "ISSHT",
    "ENIT",
    "ESSTST",
    "ULT",
    "IMSET",
    "Groupe Centrale",
    "TekUp",
    "Sesame",
    "IHET",
  ],
  "Campus Tunis": [
    "FSB",
    "ISETB",
    "ISSAT Mateur",
    "Enib",
    "ISG B",
    "IPEIB",
    "ISPAB",
    "ESA Mateur",
  ],
};
export default universities;
